import { styled, keyframes } from "@mui/system";

const effect = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;

const EffectDiv = styled("div")`
  overflow: hidden;
  white-space: nowrap;
  animation: ${effect} 2s;
`;

const FadeIn = ({ trigger, children }) => {
  return <EffectDiv key={trigger}>{children}</EffectDiv>;
};
export default FadeIn;
