function isMobileDevice() {
  const res =
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1;
  return res;
}

function isTabletOrMobileDevice() {
  return /iPad|Android|Touch/i.test(navigator.userAgent) || isMobileDevice();
}

export { isMobileDevice, isTabletOrMobileDevice };
