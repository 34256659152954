import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import lottie from "lottie-web";
import lottie_sm_loading from "assets/lottie_sm_loading.json";

const Loader = () => {
  const container = useRef(null);
  const animation = useRef(null);

  useEffect(() => {
    animation.current = lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: lottie_sm_loading,
    });

    return () => {
      animation.current.destroy();
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        maxHeight: "100vh",
        height: "100vh",
        backgroundColor: "#fff",
      }}
    >
      <div ref={container} style={{ width: 100, height: 100 }} />
    </Box>
  );
};

export default Loader;
