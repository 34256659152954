import { useTranslation } from "react-i18next";

//Styles
import styles from "./CheckBox.module.css";

export default function CheckBox({ field, form }) {
  const { t } = useTranslation();
  const error = form.errors[field.name];
  const touched = form.touched[field.name];

  return (
    <div>
      <div className={styles.checkbox}>
        <input
          {...field}
          type="checkbox"
          id="terms"
          name="terms"
          checked={field.value}
          className={styles.checkboxInput}
        />
        <label htmlFor="terms">
          {t("Home.lb_form_terms_checkbox_1")}
          <a
            href="/terms-and-conditions"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.termsLink}
          >
            {t("Home.lb_form_terms_checkbox_2")}
          </a>
        </label>
      </div>
      {error && touched && <div className={styles.errorMessage}>{error}</div>}
    </div>
  );
}
