import React, { useEffect, useState } from "react";
import styles from "pages/Legal/legalPages/Legal.module.css";
import { getLegalData } from "services/legalRepository";
import LinearProgress from "@mui/material/LinearProgress";

export default function LegalLayout({ StorageFileName }) {
  const [data, setData] = useState(null);

  //Fetch Json file from firebase storage
  useEffect(() => {
    async function fetchData() {
      const fetchedData = await getLegalData(StorageFileName);
      setData(fetchedData);
    }
    fetchData();
    // eslint-disable-next-line
  }, []);
  if (!data) return <LinearProgress color="inherit" />;

  const renderPoints = (points) => {
    return (
      <ol className={styles.List}>
        {points.map((point, index) => (
          <li key={index}>
            {point.title && <strong>{point.title}</strong>}
            {point.text && (
              <p dangerouslySetInnerHTML={{ __html: point.text }} />
            )}
            {point.points && renderPoints(point.points)}
          </li>
        ))}
      </ol>
    );
  };

  return (
    <div className={styles.Container}>
      <h1>{data["title"]}</h1>
      <p>Effective Date: {data["effective_date"]}</p>

      <p>{data["text"]}</p>

      {/* ============ Points  ============*/}
      <ol className={styles.List}>
        {data.points.map((point, index) => (
          <li key={index}>
            {point.title && <strong>{point.title}</strong>}
            {point.text && (
              <p dangerouslySetInnerHTML={{ __html: point.text }} />
            )}
            {point.points && renderPoints(point.points)}
          </li>
        ))}
      </ol>
      {/* ==================================*/}
    </div>
  );
}
