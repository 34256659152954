import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    lng:
      localStorage.getItem("LANGUAGE") ||
      navigator.language ||
      navigator.userLanguage ||
      "en", // Set the default language
    fallbackLng: "en", // Fallback language if translation is missing
    interpolation: {
      escapeValue: false, // Disable HTML escaping
    },
  });

export default i18n;

//=========== usage ===========//

// import { useTranslation } from 'react-i18next';

// const { t } = useTranslation();

// t('System.lb_login');
// {t('System.lb_logout')}
