/**
 * Get Image for Path using image Service
 * @param {string} path path of image
 * @param {number} width size
 * @param {number} height size
 * @param {string} format jpg, png, webp
 * @returns url build path for image desired
 */
export function getImageForPath(path, width, height, format) {
  if (!path || !width || !height || !format) {
    return undefined;
  }
  const baseUrl = process.env.REACT_APP_IMAGE_SERVICE_BASE_URL.endsWith('/') ? process.env.REACT_APP_IMAGE_SERVICE_BASE_URL : `${process.env.REACT_APP_IMAGE_SERVICE_BASE_URL}/`;
  const fullUrl = `${baseUrl}image?width=${width}&height=${height}&format=${format}&path=${path}`;
  return fullUrl;
}
