import { Grid } from "@mui/material";

//Styles
import styles from "../company.module.css";

//Component
import Copyright from "components/Copyright";

//Utils
import getSystem from "utils/getOperatingSystem";

export default function DownloadApp() {
  return (
    <Grid
      container
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#ffffff",
        zIndex: 2,
        p: getSystem() === "ios" ? "10px 0px 18px 0px" : "10px 0px 10px 0px",
      }}
    >
      <Copyright className={styles.copyRight} />
    </Grid>
  );
}
