import { Helmet } from "react-helmet";
import LegalLayout from "../../components/LegalLayout";

export default function PrivacyPolicy() {
  return <div>
    <Helmet>
      <title>Swift Menu App - Privacy Policy!</title>
    </Helmet>
    <LegalLayout StorageFileName={"PrivacyPolicy.json"} />
  </div>;
  
}
