import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import "./i18n";

//ErrorBoundary
import ErrorBoundary from "./ErrorBoundary/index";

// Firebase
import App from "./App";
import { store } from "./store";

// Importing Fonts (befonts.com)
import "./assets/Font-Family/Nohemi-Medium.ttf";
import "./assets/Font-Family/Uncut-Sans-VF.ttf";

//Monitoring
import initializeSentry from "./SentryConfig";
import SentryRemoteLogger from "./SentryConfig/sentry_remote_logger";
import { monitoring } from "@Ethical-Algorithm/reactjs-ea-logger-kit";

initializeSentry();

const remoteLogger = new SentryRemoteLogger();
monitoring.setupLoggers([remoteLogger]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);
