import { io } from "socket.io-client";

//Firebase
import { getToken } from "firebase/app-check";
import { appCheck } from "FirebaseConfig/Firebase";

//Monitoring
import { monitoring } from "@Ethical-Algorithm/reactjs-ea-logger-kit";

export const authenticateWithAppCheck = async () => {
  try {
    const appCheckToken = await getToken(appCheck, true);
    const newSocket = io(process.env.REACT_APP_WEBSOCKET_URL, {
      auth: {
        token: appCheckToken.token,
      },
    });
    return newSocket;
  } catch (error) {
    monitoring.error("Error obtaining App Check token:", error);
  }
};
