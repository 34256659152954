import React, { useState } from "react";
import {
  Button,
  Link,
  Typography,
  Fade,
  Paper,
  Grid,
  Backdrop,
} from "@mui/material";
import TrapFocus from "@mui/material/Unstable_TrapFocus";
import img_swift_menu_dark from "assets/img_swift_menu_dark.svg";
import { useTranslation } from "react-i18next";

import { monitoring } from "@Ethical-Algorithm/reactjs-ea-logger-kit";

export default function CookiesConsent() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(
    !document.cookie.includes("cookiesConsent=")
  );

  const handleCookies = (res) => {
    const date = new Date();
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000); // 1 year of expiration
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `cookiesConsent=${res}; ${expires}; path=/`;

    setOpen(false);
    monitoring.breadcrumb("[Visited Home]", "User visited Home", "info");
  };

  return (
    <>
      <Backdrop open={open} style={{ zIndex: 9999, color: "#fff" }} />
      <TrapFocus open disableAutoFocus disableEnforceFocus>
        <Fade appear={false} in={open}>
          <Paper
            role="dialog"
            aria-modal="false"
            aria-label="Cookie banner"
            square
            variant="outlined"
            tabIndex={-1}
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              m: 0,
              p: 2,
              borderWidth: 0,
              borderTopWidth: 1,
              zIndex: 10000,
            }}
          >
            <Grid
              container
              sx={{ display: "flex" }}
              columns={{ xs: 6, sm: 12, md: 12 }}
              spacing={2}
            >
              <Grid item xs={6} md={6} sx={{ flex: 1, m: "auto" }}>
                <img
                  alt="img_swift_menu_dark"
                  src={img_swift_menu_dark}
                  style={{ width: "100px", marginBottom: "10px" }}
                />
                <Typography
                  sx={{ fontSize: "12px", mb: "10px", maxHeigth: "50px" }}
                >
                  To provide the best experiences, we use technologies like
                  cookies to store and/or access device information. Consenting
                  to these technologies will allow us to process data such as
                  browsing behavior or unique IDs on this site. Not consenting
                  or withdrawing consent, may adversely affect certain features
                  and functions.
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Grid
                  sx={{
                    maxWidth: "500px",
                    justifycontent: "center",
                    m: "auto",
                  }}
                >
                  <Typography>Manage Consent</Typography>
                  <Button
                    variant="contained"
                    onClick={() => handleCookies("all")}
                    sx={{
                      width: "100%",
                      mt: "10px",
                      fontSize: "12px",
                    }}
                  >
                    {t("System.lb_cookies_accept_all")}
                  </Button>

                  <Button
                    variant="contained"
                    onClick={() => handleCookies("essential")}
                    sx={{
                      width: "100%",
                      mt: "10px",
                      fontSize: "12px",
                    }}
                  >
                    {t("System.lb_cookies_accept_essential")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid sx={{ mt: "10px" }}>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="/cookie-policy"
                sx={{ fontSize: "12px", mr: "10px" }}
              >
                {t("System.lb_cookie_policy")}
              </Link>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="/privacy-policy"
                sx={{ fontSize: "12px" }}
              >
                {t("System.lb_privacy_policy")}
              </Link>
            </Grid>
          </Paper>
        </Fade>
      </TrapFocus>
    </>
  );
}
