import Routes from "App/Routes";

//MUI / Swiper / Styles
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "swiper/swiper-bundle.css"; //This is important to import for swiper to work

import SnackbarProvider from "../store/contexts/GlobalMessages/SnackbarProvider";
import CookiesConset from "../components/CookiesConset";

//Monitoring
import { monitoring } from "@Ethical-Algorithm/reactjs-ea-logger-kit";
import { v4 as uuidv4 } from "uuid";

//Utils
import getSystem from "utils/getOperatingSystem";
import getCookie from "utils/getCookie";
// import { runMigrations } from "utils/migrations";

const iosTheme = createTheme({
  typography: {
    fontFamily: '"Helvetica Neue", Arial, sans-serif',
  },
});

const androidTheme = createTheme({
  typography: {
    fontFamily: '"Roboto", Arial, sans-serif',
  },
});

const otherTheme = createTheme({
  typography: {
    fontFamily: '"Roboto", Arial, sans-serif',
  },
});

const theme =
  getSystem() === "ios"
    ? iosTheme
    : getSystem() === "android"
    ? androidTheme
    : otherTheme;

function App() {
  //=================| Set/Get userID-Cookies |=================//

  if (!getCookie("userID")) {
    const newId = uuidv4();
    //This "path=/" is to make the cookie available in all pages, not just in the current page.
    document.cookie = `userID=${newId}; path=/`;

    //Cookies can be [undefined, all, essential]
    monitoring.login(getCookie("cookiesConsent") === "all", {
      id: newId,
      userSystem: getSystem(),
    });
  } else {
    const id = getCookie("userID");

    monitoring.login(getCookie("cookiesConsent") === "all", {
      id: `[Cookies-id]:${id}`,
      userSystem: getSystem(),
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <Routes />
        <CookiesConset />
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
