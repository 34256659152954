import { configureStore } from "@reduxjs/toolkit";

import { setupListeners } from "@reduxjs/toolkit/query";

// project import
import rootReducer from "./reducers";

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const store = configureStore({
  reducer: rootReducer,
});

const { dispatch } = store;
export { store, dispatch };

setupListeners(store.dispatch);
