import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

//Monitoring
import { monitoring } from "@Ethical-Algorithm/reactjs-ea-logger-kit";

//Contexts
import { SnackbarContext } from "store/contexts/GlobalMessages/SnackbarContext";

//Material Ui / Styles
import { Grid, Tabs, Tab, styled, Alert } from "@mui/material";
import styles from "./company.module.css";

//Components
import Product from "./components/Product";
import OrderPrice from "./components/OrderButton";
import LoaderPage from "./components/UI/LoaderPage";
import PoweredBy from "./components/UI/PoweredBy";
import Cart from "./components/BottomSheet/Cart";
import Details from "./components/BottomSheet/Details";
import { Swiper, SwiperSlide } from "swiper/react";
import CopyRight from "./components/CopyRight";

//Assets
import img_banner from "assets/img_banner.webp";
import img_vector from "assets/img_vector.webp";

//Services
import {
  fetchCompanyById,
  fetchMenuItemsByCompanyId,
} from "services/companyRepository";

//Utils
import useCurrencyFormatter from "utils/Hooks/useCurrencyFormatter";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { v4 as uuidv4 } from "uuid";

//Socket
import { authenticateWithAppCheck } from "./socket";

//Routes
import { ROUTES } from "App/Routes";
import { getImageForPath } from "utils/imageUtils";
import { Helmet } from "react-helmet";

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: "none",
  color: "var(--Black, #0E0E0E)",
  padding: "20px 16px 17px",

  "&.Mui-selected": {
    color: "black",
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const StyledTabs = styled((props) => <Tabs {...props} />)(({ theme }) => ({
  boxShadow: "#F2F2F2 0px -3px 0px 0px inset",
  "& .MuiTabs-indicator": {
    backgroundColor: "#ffffff00",
    borderBottom: "3px solid #000",
    height: "3px",
  },
}));

const Company = () => {
  useEffect(() => {
    document.body.style.backgroundColor = "black";

    //This will execute when the component is unmounted
    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, []);

  const { t } = useTranslation();
  const showSnackbar = useContext(SnackbarContext);
  //Hoocks / Custom Hoocks
  const formatCurrency = useCurrencyFormatter();
  const Navigate = useNavigate();
  const location = useLocation();

  //This will store the selected product to show the details
  const [selectedProduct, setSelectedProduct] = useState({});

  const [companyData, setCompanyData] = useState({}); //company document(firebase)
  const [products, setProducts] = useState([]); //menu items colection(firebase)

  //Get the company id from the url
  const params = useParams();
  const companyID = params.companyId;
  //Get tbale number from the url (using search params)
  const [searchParams] = useSearchParams();
  const tableID = searchParams.get("table");

  // Acessando as propriedades do objeto location
  const pathname = location.pathname;
  const search = location.search; // Company ID
  const hash = location.hash; // Table ID

  // Montando a URL
  const landingUrl = process.env.REACT_APP_LANDING_PAGE_URL;
  const url = `${landingUrl}${pathname}${search}${hash}`;

  let tableNumber = null;
  try {
    const entries = Object.entries(companyData?.qrCodes || {});
    const foundEntry = entries.find(([key, value]) => value === url);
    tableNumber = foundEntry ? foundEntry[0] : null;
  } catch (error) {
    monitoring.log(`Erro trying to get the table id: ${error}`, error);
  }

  //Redux
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.myList.products);

  //==========================| State |==========================
  //Tabs value
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  //------- Dialogs Open --------
  const [openCart, setOpenCart] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  //-----------------------------
  //Get the user language from the local storage or the browser
  const userLanguage = (
    localStorage.getItem("LANGUAGE") ||
    navigator.language ||
    navigator.userLanguage
  ).split("-")[0];

  // //==============================================================

  //Swiper value
  const swiperRef = useRef(null);
  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(value);
    }
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //=========================| Cart Total |==========================
  //This will calculate the total price of the cart
  const total = useMemo(() => {
    let total = 0;
    cart.map((item) => {
      //Calculate the price of ish product(portion + extras * quantity)
      const portionPrice = item.portion.price;
      const extrasPrice =
        item.extras?.reduce((acc, extra) => acc + Number(extra.price), 0) || 0;

      total += (portionPrice + extrasPrice) * item.quantity;
      return null;
    });
    return Number(total.toFixed(2));
  }, [cart]);

  //=========================| Add to Redux/Cart |==========================
  const verifyIfProductIsInCart = (itemToCart, item) => {
    return (
      item.info.id === itemToCart.selectedProduct.id &&
      item.portion === itemToCart.selectedPortion &&
      JSON.stringify(Object.values(item.extras).sort()) ===
        JSON.stringify(
          itemToCart.selectedExtras ? itemToCart.selectedExtras.sort() : []
        )
    );
  };

  const addToCart = useCallback(
    (itemToCart) => {
      if (cart.some((item) => verifyIfProductIsInCart(itemToCart, item))) {
        //If the product is already in the cart, search the product and update the quantity
        const newItemQuantity = cart.map((item) => {
          if (verifyIfProductIsInCart(itemToCart, item))
            return { ...item, quantity: item.quantity + 1 };

          return item;
        });
        dispatch({ type: "myList/updateList", payload: newItemQuantity });
        return;
      } else {
        //If the product is not in the cart, add the product
        dispatch({
          type: "myList/addItem",
          payload: {
            info: { ...itemToCart.selectedProduct },
            id: uuidv4(),
            quantity: 1,
            portion: itemToCart.selectedPortion,
            extras: itemToCart.selectedExtras ? itemToCart.selectedExtras : [],
          },
        });
      }
    },
    // eslint-disable-next-line
    [cart]
  );

  //---Fetch the company document and the menu items collection---
  useEffect(() => {
    setIsLoading(true);
    const fetchProducts = async () => {
      const company = await fetchCompanyById(companyID);
      const Products = await fetchMenuItemsByCompanyId(companyID);
      if (!company) {
        showSnackbar(t("menu.lb_cant_find_company_id"), "error");
        Navigate(ROUTES.NOT_FOUND);
        throw new Error(t("menu.lb_cant_find_company_id"));
      } else {
        setCompanyData(company);
        setProducts(Products);
      }
      setIsLoading(false);
    };
    fetchProducts();
    // eslint-disable-next-line
  }, []);

  //This will get a random number for the banner
  const [randomInt, setRandomInt] = useState(null);
  useEffect(() => {
    if (companyData?.banners) {
      const max = companyData.banners.length;
      setRandomInt(Math.floor(Math.random() * max));
    }
  }, [companyData]);

  //========================= Parallax Effect ===========================
  useEffect(() => {
    const handleScroll = () => {
      var banner = document.getElementById("banner");
      var logo = document.getElementById("logo");
      if (banner) {
        let opacity = Math.max(1 - window.pageYOffset / 250, 0);
        let top = Math.max(+window.pageYOffset / 2);
        let backgroundPosition = "center " + top * 0 + "px";
        banner.style.backgroundPosition = backgroundPosition;
        banner.style.top = top + "px";
        logo.style.opacity = opacity;
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  //====================| Socket |====================//
  const socketRef = useRef(null);

  useEffect(() => {
    const initSocket = async () => {
      if (socketRef.current) return; // Verifica se o socket já está inicializado
      const resolvedSocket = await authenticateWithAppCheck();
      socketRef.current = resolvedSocket;
    };

    if (companyID) initSocket();

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [companyID]);

  //=================================================//

  return (
    <>
      <Helmet>
        <title>{`${companyData.name || "..."} - Swift Menu` || "Swift Menu"}</title>
      </Helmet>
      {isLoading ? (
        <LoaderPage />
      ) : (
        <Grid className={styles.companyContainer}>
          {/* ================| Banner |================ */}
          <Grid
            id="banner"
            className={styles.banner}
            style={{
              backgroundImage: `url(${
                getImageForPath(companyData.banners?.[randomInt], 1200, 400, "webp") || img_banner
              })`,
              position: "relative",
            }}
          >
            <img
              id="logo"
              alt={getImageForPath(companyData.logoUrl, 192, 192, "webp")}
              src={getImageForPath(companyData.logoUrl, 192, 192, "webp") || img_vector}
              style={{
                height: "96px",
                borderRadius: "50%",
              }}
            />
            <PoweredBy />
          </Grid>

          <div className={styles.productsContainer}>
            {/* ================| Tab |================ */}
            {companyData.categories && (
              <StyledTabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons={false}
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                  backgroundColor: "#fff",
                  color: "#000",
                }}
              >
                {companyData.categories &&
                  companyData.categories.map((item, index) => (
                    <StyledTab
                      key={index}
                      label={capitalizeFirstLetter(
                        item.translation[userLanguage] ||
                          item.translation[companyData.defaultLanguage]
                      )}
                    />
                  ))}
              </StyledTabs>
            )}

            {/* ================| Product List |================ */}
            <div className={styles.productList}>
              <Swiper
                spaceBetween={50}
                slidesPerView={1}
                onSlideChange={(swiper) => {
                  setValue(swiper.activeIndex);
                }}
                onSwiper={(swiper) => (swiperRef.current = swiper)}
                style={{ zIndex: 0 }}
                autoHeight={true}
              >
                {companyData.categories?.map((category) => (
                  <SwiperSlide key={category.id}>
                    {products
                      .filter((product) => product.category === category.id)
                      .map((item) => {
                        return (
                          <Product
                            key={item.id}
                            product={item}
                            userLanguage={userLanguage}
                            defaultLanguage={companyData.defaultLanguage}
                            currencyCode={companyData.currencyCode}
                            setOpen={setOpenDetails}
                            setSelectedProduct={setSelectedProduct}
                            addToCart={addToCart}
                            onClick={() => setSelectedProduct(item)}
                          />
                        );
                      })}
                  </SwiperSlide>
                ))}
                {products.length === 0 && (
                  <div className={styles.alert}>
                    <Alert severity="info" sx={{ m: "auto" }}>
                      This company currently has no products available online!
                    </Alert>
                  </div>
                )}
              </Swiper>
            </div>
            {
              cart.length > 0 && (
                <OrderPrice
                  features={companyData.features}
                  price={formatCurrency(total)}
                  setOpen={setOpenCart}
                />
              )
              // : (
              //   <DownloadApp />
              // )
            }
            <CopyRight />
          </div>
        </Grid>
      )}

      <Cart
        socket={socketRef.current}
        open={openCart}
        setOpen={setOpenCart}
        cart={cart}
        total={total}
        userLanguage={userLanguage}
        currencyCode={companyData.currencyCode}
        defaultLanguage={companyData.defaultLanguage}
        companyID={companyID}
        tableNumber={tableNumber}
        tableID={tableID}
        features={companyData.features}
      />
      <Details
        open={openDetails}
        setOpen={setOpenDetails}
        selectedProduct={selectedProduct}
        addToCart={addToCart}
        userLanguage={userLanguage}
        defaultLanguage={companyData.defaultLanguage}
        currencyCode={companyData.currencyCode}
        features={companyData.features}
      />
    </>
  );
};
export default Company;
