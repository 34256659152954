import { useTranslation } from "react-i18next";
import * as React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useContext } from "react";

//CSS Module
import styles from "./Footer.module.css";

//Component
import Input from "../inputs/Input";
import InputTextArea from "../inputs/InputTextArea";
import Checkbox from "../inputs/CheckBox";
import Copyright from "components/Copyright";

//Services
import { sendContactForm } from "services/homeRepository";

//Contexts
import { SnackbarContext } from "store/contexts/GlobalMessages/SnackbarContext";
import { monitoring } from "@Ethical-Algorithm/reactjs-ea-logger-kit";

const schema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Email is not valid").required("Email is required"),
  phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, "Phone number is not valid")
    .min(8, "Phone number is not valid")
    .max(15, "Phone number is not valid"),
  terms: Yup.bool().oneOf([true], "You must accept the terms and conditions"),
});

export default function Footer() {
  const showSnackbar = useContext(SnackbarContext);
  const { t } = useTranslation();
  const handleSendContactForm = (values) => {
    try {
      sendContactForm(
        values.name,
        values.email,
        values.phoneNumber,
        values.message,
        showSnackbar,
        t("Home.lb_message_sent_success"),
        t("Home.lb_message_sent_error")
      );
    } catch (error) {
      //TODO: Handle showSnackbar error here, instead of in the service
      monitoring.error("Error on sending contact form:", error);
    }
  };

  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerContainerFlex}>
        <div className={styles.footerContainerLeft}>
          <div className={styles.footerIcon}>🚀</div>
          <div className={styles.footerContainerText}>
            {t("Home.lb_form_title_1")}
            <br />
            {t("Home.lb_form_title_2")}
          </div>
        </div>
        <Formik
          initialValues={{
            name: "",
            email: "",
            phoneNumber: "",
            message: "",
            terms: false,
          }}
          validationSchema={schema}
          //TODO: onSubmit={(values, { setSubmitting, resetForm }) => {
          //   handleSendContactForm(values)
          //     .then(() => {
          //       setSubmitting(false);
          //       resetForm();
          //     })
          //     .catch(() => {
          //       setSubmitting(false);
          //     });
          // }}

          onSubmit={(values, { resetForm }) => {
            handleSendContactForm(values);
            resetForm();
          }}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {/* {({ isSubmitting }) => ( */}
          <Form id="contactOnHome" className={styles.footerContainerRight}>
            <Field
              name="name"
              type="text"
              placeholder={t("Home.lb_form_name_input")}
              component={Input}
            />
            <Field
              name="email"
              type="text"
              placeholder={t("Home.lb_form_email_input")}
              component={Input}
            />
            <Field
              name="phoneNumber"
              type="text"
              placeholder={t("Home.lb_form_phone_input")}
              component={Input}
            />
            <Field
              name="message"
              placeholder={t("Home.lb_form_questons_input")}
              component={InputTextArea}
            />
            <Field name="terms" component={Checkbox} />
            {/* {isSubmitting ? (
              <button type="submit" className={styles.buttonPrimaryLoading}>
                <CircularProgress color="inherit" />
              </button>
            ) : ( */}
            <button type="submit" className={styles.buttonPrimary}>
              {" "}
              {t("Home.lb_form_button")}
            </button>
            {/* )} */}
          </Form>
          {/* )} */}
        </Formik>
      </div>
      <Copyright className={styles.copyRight} />
    </div>
  );
}
