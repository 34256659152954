//CSS Module
import { useField } from "formik";
import styles from "./InputTextArea.module.css";

export default function InputTextArea(props) {
  const [field] = useField("message");
  return (
    <textarea {...props} {...field} className={styles.textarea}></textarea>
  );
}
