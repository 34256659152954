import { Divider, Grid, ButtonBase } from "@mui/material";
import ic_remove from "assets/icons/ic_remove.svg";
import ic_add from "assets/icons/ic_add.svg";

//Styles
import styles from "../cart.module.css";

export default function QuantityInput({ item, addQuantity, removeQuanity }) {
  return (
    <Grid
      item
      xs={0}
      sx={{
        display: "flex",
        backgroundColor: "#ffffff",
        borderRadius: "100px",
        boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.06)",
        height: "32px",
        padding: "1px 4px 1px 4px",
        alignItems: "center",
        justifyContent: "center",
        gap: "2px",
      }}
    >
      <ButtonBase
        className={styles.quantityInputIconButton}
        onClick={() => removeQuanity(item)}
      >
        <img alt="remove" src={ic_remove} />
      </ButtonBase>

      <Divider
        orientation="vertical"
        flexItem
        sx={{
          height: "16px",
          m: "auto",
        }}
      />

      <div
        style={{
          width: "30px",
          height: "30px",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          borderRadius: "100px",
        }}
      >
        {item.quantity}
      </div>

      <Divider
        orientation="vertical"
        flexItem
        sx={{
          height: "16px",
          m: "auto",
        }}
      />

      <ButtonBase
        className={styles.quantityInputIconButton}
        onClick={() => addQuantity(item)}
      >
        <img alt="remove" src={ic_add} />
      </ButtonBase>
    </Grid>
  );
}
