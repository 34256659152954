import { initializeApp } from "firebase/app";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import { configureAppCheckDebugToken, firebaseConfig, reCaptchaKey } from "./appConfig";

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

//App Check
export const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaEnterpriseProvider(reCaptchaKey),
  isTokenAutoRefreshEnabled: true,
});

export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
export const analytics = getAnalytics(firebaseApp);

//--------------------> appCheck
configureAppCheckDebugToken();

//--------------------> emulators
if (process.env.REACT_APP_IS_LOCALHOST === "true")
  connectFirestoreEmulator(
    db,
    "localhost",
    process.env.REACT_APP_FIRESTORE_EMULATOR_PORT
  ); 
if (process.env.REACT_APP_IS_LOCALHOST === "true") {
  // Connect to the Storage Emulator
  connectStorageEmulator(
    storage,
    "localhost",
    process.env.REACT_APP_STORAGE_EMULATOR_PORT
  );
}
