import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { RouteTracker } from "App/components/RouteTracker";

//Pages
import Home from "pages/Home";
import Company from "pages/Company";
import Legal from "pages/Legal";
import PageNotFound from "pages/PageNotFound";
import PrivacyPolicy from "pages/Legal/legalPages/PrivacyPolicy";
import TermsAndConditions from "pages/Legal/legalPages/TermsAndConditions";
import CookiePolicy from "pages/Legal/legalPages/CookiePolicy";

export default function RoutesConfig() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/*" element={<PageNotFound />} />
        <Route exact path="/company/:companyId" element={<Company />} />

        {/* ======================== Legal Pages ======================== */}
        <Route
          path="/terms-and-conditions"
          element={
            <Legal>
              <TermsAndConditions />
            </Legal>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <Legal>
              <PrivacyPolicy />
            </Legal>
          }
        />
        <Route
          path="/cookie-policy"
          element={
            <Legal>
              <CookiePolicy />
            </Legal>
          }
        />
        {/* =============================================================== */}
      </Routes>
      <RouteTracker />
    </Router>
  );
}

export const ROUTES = {
  HOME: "/",
  NOT_FOUND: "/404",
  COMPANY: "/company",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
  PRIVACY_POLICY: "/privacy-policy",
  COOKIE_POLICY: "/cookie-policy",
};
