import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
};

const myListSlice = createSlice({
  name: "myList",
  initialState,
  reducers: {
    updateList(state, action) {
      state.products = action.payload;
    },

    addItem(state, action) {
      state.products.push(action.payload);
    },
    removeItem(state, action) {
      state.products = state.products.filter(
        (item) => item.id !== action.payload
      );
    },
    addQuantity(state, action) {
      const index = state.products.findIndex(
        (item) => item.id === action.payload
      );
      state.products[index].quantity++;
    },
    removeQuantity(state, action) {
      const index = state.products.findIndex(
        (item) => item.id === action.payload
      );
      state.products[index].quantity--;
    },
    //TODO: This function is not being used in the project
    changeSize(state, action) {
      const index = state.products.findIndex(
        (item) => item.id === action.payload.id
      );
      state.products[index].size = action.payload.size;
    },
  },
});

export const { addItem, removeItem } = myListSlice.actions;

export default myListSlice.reducer;
