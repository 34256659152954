import React from "react";
import { useTranslation } from "react-i18next";

//CSS Module
import styles from "./Steps.module.css";

//Assests
import img_prot_one from "assets/HomePage/img_prot_one.webp";
import img_prot_two from "assets/HomePage/img_prot_tow.webp";
import img_prot_three from "assets/HomePage/img_prot_three.webp";
import img_wavy_arrow from "assets/HomePage/img_wavy_arrow.svg";
import img_wavy_arrow_tow from "assets/HomePage/img_wavy_arrow_tow.svg";

//Components
import NotifyInput from "../inputs/NotifyInput";

const arrowImages = {
  1: img_wavy_arrow,
  2: img_wavy_arrow_tow,
};

const StepsInfo = [
  {
    id: 1,
    title: "Home.lb_steps_1",
    img: img_prot_one,
  },
  {
    id: 2,
    title: "Home.lb_steps_2",
    img: img_prot_two,
  },
  {
    id: 3,
    title: "Home.lb_steps_3",
    img: img_prot_three,
  },
];

export default function Steps() {
  const { t } = useTranslation();

  return (
    <div id="landing-page-how-it-works" className={styles.StepsContainer}>
      <div>
        <div className={styles.StepsTitle}>{t("Home.lb_steps_main_title")}</div>
        <div className={styles.StepsText}>
          {t("Home.lb_steps_secondary_title")}
        </div>
      </div>

      <div className={styles.StepsFlex}>
        {StepsInfo.map((step) => (
          <React.Fragment key={step.id}>
            <div className={styles.StepsBox}>
              <div className={styles.ProtTitle}>
                <div style={{ marginRight: "5px" }}>{step.id}.</div>
                {t(step.title)}
              </div>
              <img src={step.img} alt={`Prot${step.id}`} />
            </div>
            {step.id < 3 && (
              <div className={styles.ArrowContainer}>
                <img
                  className={styles[`Arrow${step.id}`]}
                  src={arrowImages[step.id]}
                  alt={`arrow${step.id}`}
                />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
      <NotifyInput />
    </div>
  );
}
