import { useTranslation } from "react-i18next";

//CSS Module
import styles from "./Intro.module.css";

//Assets
import img_intro_left from "assets/HomePage/img_intro_left.webp";
import img_intro_right from "assets/HomePage/img_intro_right.webp";
import img_intro_vector from "assets/HomePage/img_intro_vector.svg";
import img_intro_vector_tow from "assets/HomePage/img_intro_vector_tow.svg";
import ic_arrow_down from "assets/icons/ic_arrow_down.svg";

//Components
import NotifyInput from "../inputs/NotifyInput";

const scrollToContainer = () => {
  const container = document.getElementById("landing-page-how-it-works");
  const contactPosition =
    container.getBoundingClientRect().top + window.pageYOffset - 100;
  window.scrollTo({ top: contactPosition, behavior: "smooth" });
};

export default function Intro() {
  const { t } = useTranslation();

  return (
    <div className={styles.introContainer}>
      <div style={{ position: "relative" }}>
        <div className={styles.leftImage}>
          <img src={img_intro_left} alt="introImage" />
          <img
            className={styles.introImageVector}
            style={{ position: "absolute", top: 10, left: 290 }}
            src={img_intro_vector}
            alt="img_intro_vector"
          />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className={styles.introTitlesContainer}>
          <div className={styles.introFrame}>{t("Home.lb_intro_title")}</div>
          <div className={styles.mainTitle}>
            {t("Home.lb_intro_main_title")}
          </div>
          <div className={styles.secondaryTitle}>
            {t("Home.lb_intro_secondary_title")}
          </div>
        </div>
        <NotifyInput />
        <div className={styles.howWorksScrollBtn} onClick={scrollToContainer}>
          <img src={ic_arrow_down} alt="arrowDown" />
          {t("Home.lb_steps_main_title")}
        </div>
      </div>
      <div className={styles.rightImage}>
        <img src={img_intro_right} alt="introImage" />
        <img
          style={{ position: "absolute", zIndex: 1, top: 27, right: 85 }}
          src={img_intro_vector_tow}
          alt="img_intro_vector_tow"
        />
      </div>
    </div>
  );
}
