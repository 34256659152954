export const getSmallestItemPrice = (portions) => {
  if (portions.length === 0) return null;
  let smallestItemPrice = portions[0];
  for (let i = 1; i < portions.length; i++) {
    if (portions[i].price < smallestItemPrice.price) {
      smallestItemPrice = portions[i];
    }
  }
  return smallestItemPrice;
};
