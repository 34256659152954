import { useTranslation } from "react-i18next";
import styles from "../Details.module.css";

//Material Ui
import { Box, Grid, Divider, ButtonBase } from "@mui/material";

//Utils / Hoocks
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

export default function ProductPortions({
  currencyCode,
  portions,
  setSelectedPortion,
  selectedPortion,
  selectedPortionPrice,
  userLanguage,
  defaultLanguage,
}) {
  const { t } = useTranslation();
  return (
    <>
      {/*This line will only show the portions if have more than 1 option */}
      {/* {portions.length > 1 && ( */}
      <Grid className={styles.portionsContainer} item>
        <Grid container className={styles.portionsContainerHeader}>
          <div style={{ fontSize: "20px" }}>{t("menu.lb_menu_size")}</div>
          <div className={styles.portionsContainerHeaderRequired}>
            {t("menu.lb_menu_details_required")}
          </div>
        </Grid>

        {portions.map((portion, index, array) => {
          const isLastItem = index === array.length - 1;
          const hasMultipleItems = array.length > 1;
          return (
            <div key={portion.id}>
              <ButtonBase
                className={styles.buttonBase}
                onClick={() => setSelectedPortion(portion)}
              >
                <Box className={styles.optionComponet}>
                  <div style={{ textAlign: "left" }}>
                    <div style={{ lineHeight: "20px" }}>
                      {capitalizeFirstLetter(
                        portion.translation[userLanguage] ||
                          portion.translation[defaultLanguage]
                      )}
                    </div>
                    {portion.id !== selectedPortion?.id && (
                      <div className={styles.price}>
                        {portion.price >
                          (selectedPortion && selectedPortionPrice) && "+"}
                        {Number(
                          portion.price -
                            (selectedPortion && selectedPortionPrice)
                        ).toFixed(2)}{" "}
                        {currencyCode}
                      </div>
                    )}
                  </div>

                  <input
                    className={styles.radioBox}
                    type="checkbox"
                    id="index"
                    name="price"
                    checked={selectedPortion?.id === portion.id}
                    onChange={() => setSelectedPortion(portion)}
                  />
                </Box>
              </ButtonBase>
              {!isLastItem && hasMultipleItems && (
                <Divider
                  flexItem
                  sx={{
                    border: "1px solid rgba(0, 0, 0, 0.06);",
                  }}
                />
              )}
            </div>
          );
        })}
      </Grid>
      {/* )} */}
    </>
  );
}
