//CSS Module
import styles from "./Input.module.css";

export default function Input({ field, form, type, placeholder }) {
  const error = form.errors[field.name];
  const touched = form.touched[field.name];

  return (
    <div>
      <input
        {...field}
        className={`${styles.input} ${error && touched && styles.inputError}`}
        type={type}
        placeholder={placeholder}
      />
      {error && touched && <div className={styles.errorMessage}>{error}</div>}
    </div>
  );
}
