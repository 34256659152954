import { useState } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { monitoring } from "@Ethical-Algorithm/reactjs-ea-logger-kit";

//Assets
import ic_mail from "assets/icons/ic_mail.svg";
import ic_arrow from "assets/icons/ic_arrow.svg";

//CSS Module
import styles from "./NotifyInput.module.css";

//Services
import { sendNotifyEmail } from "services/homeRepository";

//Contexts
import { SnackbarContext } from "store/contexts/GlobalMessages/SnackbarContext";

const schema = Yup.object().shape({
  email: Yup.string().email("Email is not valid").required("Email is required"),
});

export default function NotifyInput() {
  const showSnackbar = useContext(SnackbarContext);
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await schema.validate({ email });
      sendNotifyEmail(
        email,
        showSnackbar,
        t("Home.lb_email_sent_success"),
        t("Home.lb_email_sent_error"),
        t("Home.lb_user_already_subscribed")
      );
      setEmail("");
      setEmailErrorMessage(null);
    } catch (error) {
      // logError(error);
      //TODO: Handle showSnackbar error here, instead of in the service
      monitoring.error("Error on sending email:", error);
      setEmailErrorMessage(error.message);
    }
  };

  return (
    <form
      noValidate
      onSubmit={handleSubmit}
      className={styles.container}
      style={{ borderColor: emailErrorMessage ? "#FF0000" : undefined }}
    >
      <div
        className={styles.inputContainer}
        style={{ borderColor: emailErrorMessage ? "#FF0000" : undefined }}
      >
        <img style={{ width: "19px" }} src={ic_mail} alt="mailIcon" />
        <input
          className={styles.input}
          type="email"
          name="_replyto"
          placeholder={t("Home.lb_notify_input")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      {emailErrorMessage && (
        <p className={styles.errorMessage}>{emailErrorMessage}</p>
      )}
      <button type="submit" className={styles.inputButton}>
        <div className={styles.inputButtonText}>
          {t("Home.lb_notify_button")}
        </div>
        <img src={ic_arrow} alt="arrowRight" />
      </button>
    </form>
  );
}
