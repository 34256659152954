import { Fab, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function OrderPrice({ price, setOpen, features }) {
  const { t } = useTranslation();
  const openCart = () => {
    setOpen(true);
  };

  return (
    <Grid
      sx={{
        "& > :not(style)": { width: "100%" },
        position: "sticky",
        bottom: 0,
        p: "20px",
        marginBottom: "-20px",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "1200px",
        width: "100%",
        zIndex: 2,
      }}
    >
      <Fab
        variant="extended"
        size="medium"
        onClick={() => openCart()}
        sx={{
          color: "#fff",
          backgroundColor: "#000",
          textTransform: "none",
          p: "26px 8px",
          borderRadius: "999px",
          fontSize: "16px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "&:hover": {
            backgroundColor: "#000",
          },
        }}
      >
        <Grid container sx={{ display: "flex" }}>
          <Grid item xs={4} />
          <Grid item xs={4} sx={{ m: "auto" }}>
            {features?.hasOrders
              ? t("menu.lb_menu_view_order")
              : t("menu.lb_menu_view_list")}
          </Grid>

          <Grid
            item
            xs
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.12)",
              borderRadius: "999px",
              padding: "8px 10px",
              lineHeight: "17px",
              fontSize: "14px",
            }}
          >
            EUR {price}
          </Grid>
        </Grid>
      </Fab>
    </Grid>
  );
}
