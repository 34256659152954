import img_swift_menu_light from "assets/img_swift_menu_light.svg";
import { useTranslation } from "react-i18next";
export default function PoweredBy() {
  const { t } = useTranslation();
  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        right: 0,
        background: "rgba(255, 255, 255, 0.16)",
        backdropFilter: "blur(10px)",
        paddingLeft: "10px",
        paddingRight: "10px",
        borderRadius: "50px",
        margin: "12px",
        width: "auto",
        height: "20px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          color: "#fff",
          fontSize: "10px",
          fontWeight: "500",
          lineHeight: "16px",
          letterSpacing: "0.2px",
          opacity: 0.8,
          marginRight: "4px",
        }}
      >
        {t("view_powered_by_text")}
      </div>
      <img
        alt="Swift Menu"
        src={img_swift_menu_light}
        style={{ height: "8px", width: "78px" }}
      />
    </div>
  );
}
