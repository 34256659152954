import { Grid } from "@mui/material";

//Styles
import styles from "../Details.module.css";

//Utils
import useCurrencyFormatter from "utils/Hooks/useCurrencyFormatter";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import FadeIn from "utils/RenderEffects/FadeIn";

export default function ProductInfo({
  selectedProduct,
  defaultLanguage,
  currencyCode,
  userLanguage,
  totalProductPrice,
}) {
  const formatCurrency = useCurrencyFormatter();

  const productTitle = capitalizeFirstLetter(
    selectedProduct.title[userLanguage] ||
      selectedProduct.title[defaultLanguage] ||
      Object.values(selectedProduct.title)[0]
  );

  const ingredients =
    selectedProduct.ingredients[userLanguage] ||
    selectedProduct.ingredients[defaultLanguage] ||
    Object.values(selectedProduct.ingredients)[0];

  return (
    <Grid
      className={styles.productContainer}
      sx={{ m: "-44px 12px 0px 12px" }}
      item
    >
      <div style={{ fontSize: "22px" }}>{productTitle}</div>

      <FadeIn trigger={totalProductPrice}>
        <div className={styles.totalPrice}>
          {totalProductPrice
            ? `${currencyCode} ${formatCurrency(totalProductPrice)}`
            : "No Product Price Available"}
        </div>
      </FadeIn>

      <div style={{ fontSize: "13px", color: "#646464" }}>{ingredients}</div>
    </Grid>
  );
}
