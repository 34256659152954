import { Helmet } from "react-helmet";
import LegalLayout from "../../components/LegalLayout";

export default function TermsAndConditions() {
  return <div>
    <Helmet>
      <title>Swift Menu App - Terms and Conditions!</title>
    </Helmet>
    <LegalLayout StorageFileName={"TermsAndConditions.json"} />
  </div>;
}
