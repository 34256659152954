//Components
import Navbar from "./components/Navbar";
import Intro from "./components/Intro";
import Steps from "./components/Steps";
import Info from "./components/Info";
import Footer from "./components/Footer";
import ScanQR from "./components/ScanQR";
import { Helmet } from "react-helmet";
// import Pricing from "./components/Pricing";

//TODO: Add it later - Feature Components
// import Brands from "./components/Brands";
// import Video from "./components/Video";

export default function Home() {
  return (
    <div>
      <Helmet>
        <title>Swift Menu App - You're digital Menu!</title>
        <meta name="description" content="Provide your guests with a QR code to easily explore your menu and place an order. No more waiting for the waitress. Just one quick scan allows your guests to view the interactive menu and order directly. Say goodbye to waiting for service." />
      </Helmet>
      <Navbar />
      <div className="homeContainer">
        <Intro />
        {/* <Brands /> */}
        <Steps />
        {/* <Video /> */}
        <Info />
        {/* <Pricing /> */}
        <Footer />
        <ScanQR />
      </div>
    </div>
  );
}
