import { forwardRef, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { monitoring } from "@Ethical-Algorithm/reactjs-ea-logger-kit";

//Contexts
import { SnackbarContext } from "store/contexts/GlobalMessages/SnackbarContext";

//MUI / Styles
import styles from "./cart.module.css";
import { Box, IconButton, Slide, Typography, Dialog } from "@mui/material";

//Assets
import ic_add from "assets/icons/ic_add.svg";
import ic_close from "assets/icons/ic_close.svg";
import ic_info from "assets/icons/ic_info.svg";

//Utils
import useCurrencyFormatter from "utils/Hooks/useCurrencyFormatter";
import { getTimestamp } from "utils/timeManager";

//Components
import OrderButton from "./components/OrderButton";
import ProductCart from "./components/ProductCart";

//Services
import { setOrder } from "services/companyRepository";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Cart({
  socket,
  open,
  setOpen,
  cart,
  total,
  currencyCode,
  defaultLanguage,
  userLanguage,
  companyID,
  tableNumber,
  tableID,
  features,
}) {
  const showSnackbar = useContext(SnackbarContext);
  const formatCurrency = useCurrencyFormatter();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);

    // Log Analytics removed items
    cart.forEach((item) => {
      if (item.quantity === 0) {
        monitoring.breadcrumb(
          "[Remove from Cart]",
          `Item removed from cart (Company: ${companyID}, ItemID: ${item.info.id})`,
          "info"
        );
      }
    });
    //Remove Products with quantity 0 when the cart is closed
    const newCart = cart.filter((item) => item.quantity > 0);
    dispatch({ type: "myList/updateList", payload: newCart });
  };

  //-------------------------

  const handleOrder = async () => {
    //Remove Products with quantity 0
    const filtredCart = cart.filter((item) => item.quantity > 0);

    try {
      if (filtredCart.length === 0)
        return showSnackbar(t("Cart.lb_cart_empty"), "error");
      if (!tableID || !tableNumber)
        return showSnackbar(t("Cart.lb_invalid_table_id"), "error");

      //Set the order in the database/websocket
      const order = {
        companyID: companyID,
        items: filtredCart.map((item) => ({
          id: item.info.id,
          quantity: item.quantity,
          portion: item.portion,
          price: item.portion.price, //TODO: price is allready calculated in the portion
          title:
            item.info.title[defaultLanguage] ||
            item.info.title[userLanguage] ||
            item.info.title[Object.keys(item.info.title)[0]],
          extras: item.extras,
        })),
        total,
        currency: currencyCode,
        status: "requested",
        createdTimeStamp: getTimestamp(), //getTime saves in the UTC timestamp
        table: tableNumber,
        tableID: tableID,
      };

      const orderID = await setOrder(order);

      try {
        await socket.emit("order", {
          order: { ...order, id: orderID },
          id: companyID,
        });
      } catch (error) {
        throw error;
      }

      //Clear the cart on Redux
      dispatch({ type: "myList/updateList", payload: [] });

      showSnackbar(t("Cart.lb_order_success"), "success");
      monitoring.breadcrumb(
        "[Order]",
        `Order sent (Company: ${companyID}, OrderID: ${orderID})`,
        "info"
      );
      setOpen(false);
    } catch (error) {
      showSnackbar(t("Cart.lb_sending_order_error"), "error");
      monitoring.error("Error sending order: ", error);
      return;
    }
  };

  //-------------------------

  useEffect(() => {
    if (open) {
      monitoring.breadcrumb(
        "[Open Cart]",
        `Cart opened (Company: ${companyID})`
      );
    }
    //eslint-disable-next-line
  }, [open]);

  return (
    <Box>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            borderRadius: "16px 16px 0 0",
            backgroundColor: "#F5F5F5",
          },
        }}
        sx={{
          margin: "auto",
          marginBottom: "0",
          height: "90%",
          maxWidth: "800px",
        }}
      >
        <div className={styles.stickyDiv}>
          <IconButton
            onClick={handleClose}
            sx={{
              boxShadow: "0px 1px 12px 0px rgba(0, 0, 0, 0.10)",
              m: "16px",
              backgroundColor: "#fff",
              "&:hover": {
                backgroundColor: "#fff",
              },
            }}
          >
            <img src={ic_close} alt="Close" style={{ padding: "5px" }} />
          </IconButton>

          <Typography
            sx={{
              m: "16px",
              fontSize: "32px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "32px" /* 100% */,
            }}
            variant="h4"
          >
            {features?.hasOrders
              ? t("menu.lb_menu_my_order")
              : t("menu.lb_menu_my_list")}
          </Typography>
          {!features?.hasOrders && (
            <div className={styles.infoBox}>
              <img src={ic_info} alt="Edit" />
              <div>{t("menu.lb_menu_show_to_waiter")}</div>
            </div>
          )}
        </div>
        {/* ================| Product List |================ */}

        {cart.map((item) => (
          <ProductCart
            key={item.id}
            item={item}
            currencyCode={currencyCode}
            defaultLanguage={defaultLanguage}
            userLanguage={userLanguage}
          />
        ))}

        <div className={styles.bottomStickyDiv}>
          <div style={{ display: "flex" }} onClick={() => setOpen(false)}>
            <div className={styles.flexDivSecondary}>
              <div className={styles.addButton}>
                <img
                  src={ic_add}
                  alt="Close"
                  style={{ width: "24px", height: "24px" }}
                />
              </div>

              <div className={styles.addButtonText}>
                {t("menu.lb_menu_add_more_items")}
              </div>
            </div>
          </div>

          <div
            style={{
              width: "100%",
            }}
          >
            <div className={styles.totalText}>
              <div>Total: </div>

              <div>
                {currencyCode} {formatCurrency(total)}
              </div>
            </div>
          </div>
        </div>
        {features?.hasOrders && <OrderButton handleOrder={handleOrder} />}
      </Dialog>
    </Box>
  );
}
