export const getTimestamp = () => {
  return new Date().getTime();
};

//======| Get Local Time or Get Time from a timestamp |======//
export const getDate = (timestamp) => {
  const date = timestamp ? new Date(timestamp) : new Date();

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const object = {
    hours,
    minutes,
    seconds,
    day,
    month,
    year,
  };
  return object;
};
//Usage: const { hours, minutes, seconds, day, month, year } = getDate();
