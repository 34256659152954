import { combineReducers } from "redux";

import myList from "./myList";

// ==============================|| COMBINE REDUCERS ||============================== //

const rootReducer = combineReducers({
  myList,
});

export default rootReducer;
