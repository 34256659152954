function getSystem() {
  // Detect if the user is using an iOS device or Android device
  const ios = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  if (ios) {
    return "ios";
  }
  const android = /Android/.test(navigator.userAgent);
  if (android) {
    return "android";
  }
  return null;
}

export default getSystem;
