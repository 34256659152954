import img_swift_menu_dark from "assets/img_swift_menu_dark.svg";
import {
  AppBar,
  Toolbar,
  MenuItem,
  styled,
  Menu,
  IconButton,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import menuIcon from "assets/icons/ic_menu.svg";

const StyledAppBar = styled(AppBar)({
  background: "#ffffff90",
  color: "black",
  boxShadow: "0px 1px 12px 0px rgba(0, 0, 0, 0.10)",
  backdropFilter: "blur(10px)",
  position: "sticky",
  top: 0,
  zIndex: 1,
});

const activeStyles = {
  color: "#00000080",
  transitionDuration: "500ms",
};

const inactiveStyles = {
  color: "#000",
  transitionDuration: "500ms",
};

function NavLink({ to, children, onClick }) {
  const location = useLocation();
  const currentPath = location.pathname;
  const isActive = currentPath === to;

  return (
    <MenuItem
      component={Link}
      to={to}
      color="inherit"
      sx={isActive ? activeStyles : inactiveStyles}
      onClick={onClick}
    >
      {children}
    </MenuItem>
  );
}

export default function Index({ children }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <StyledAppBar position="static">
        <Toolbar
          sx={{
            justifyContent: "space-between",
            margin: "0px 15dvw",
            padding: "0px !important",
          }}
        >
          <img
            alt="img_swift_menu_dark"
            src={img_swift_menu_dark}
            style={{
              width: "100px",
            }}
          />

          <IconButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <img
              alt="menuIcon"
              src={menuIcon}
              style={{
                width: "15px",
              }}
            />
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <NavLink onClick={handleClose} to="/cookie-policy">
              Cookie Policy
            </NavLink>
            <NavLink onClick={handleClose} to="/privacy-policy">
              Privacy Policy
            </NavLink>
            <NavLink onClick={handleClose} to="/terms-and-conditions">
              Terms & Conditions
            </NavLink>
          </Menu>
        </Toolbar>
      </StyledAppBar>
      {children}
    </>
  );
}
