import { monitoring } from "@Ethical-Algorithm/reactjs-ea-logger-kit";

export const sendContactForm = (
  name,
  email,
  phoneNumber = "",
  message = "",
  showSnackbar,
  successMessage,
  errorMessage
) => {
  const mHeaders = new Headers();
  mHeaders.append("Content-Type", "application/json");
  const raw = JSON.stringify({
    name,
    email,
    phoneNumber,
    message,
  });
  const requestOptions = {
    method: "POST",
    headers: mHeaders,
    body: raw,
    redirect: "follow",
  };
  fetch(
    `${process.env.REACT_APP_URL_CONTACTUS_FUNCTION_APP}/send`,
    requestOptions
  )
    .then((response) => {
      if (!response.ok || response.status >= 400) {
        throw new Error(response.status);
      }
      return response.text();
    })
    .then(() => {
      showSnackbar(successMessage, "success");
    })
    .catch((error) => {
      monitoring.error(error);
      showSnackbar(errorMessage, "error");
      throw error;
    });
};

export const sendNotifyEmail = (
  email,
  showSnackbar,
  successMessage,
  errorMessage,
  alreadySubscribedMessage
) => {
  const mHeaders = new Headers();
  mHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    email,
  });

  const requestOptions = {
    method: "POST",
    headers: mHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(
    `${process.env.REACT_APP_URL_MAILCHIMP_FUNCTION_APP}/register-user`,
    requestOptions
  )
    .then((response) => {
      if (!response.ok || response.status >= 400) {
        throw new Error(response.status);
      }

      return response.text();
    })
    .then((message) => {
      if (message.includes("User is already subscribed")) {
        showSnackbar(alreadySubscribedMessage, "success");
      } else {
        showSnackbar(successMessage, "success");
      }
    })
    .catch((error) => {
      monitoring.error(error);
      showSnackbar(`${error.message}:${errorMessage}`, "error");
      throw error;
    });
};
