//Utils
import { getDate } from "utils/timeManager";

export default function Copyright({ className }) {
  const { year } = getDate();
  return (
    <div className={className}>
      Copyright © {year}{" "}
      <a href="https://www.ealgorithm.net/">Ethical Algorithm.</a> All Rights
      Reserved.
    </div>
  );
}
