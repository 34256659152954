import React from "react";
import { Helmet } from "react-helmet";

export default function PageNotFound() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        fontSize: "48px",
        fontWeight: "bold",
        color: "#000",
        backgroundColor: "#F5F5F5",
        fontFamily: "Roboto, sans-serif",
      }}
    >
      <Helmet>
        <title>Swift Menu App - 404: Page Not Found!</title>
      </Helmet>
      <div style={{ textAlign: "center" }}>
        <div style={{ fontSize: "72px", marginBottom: "16px" }}>404</div>
        <p style={{ fontSize: "24px", marginBottom: "32px" }}>
          This Dish is Off the Menu!<br/>Please, go back and try again.
        </p>
      </div>
    </div>
  );
}
