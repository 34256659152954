import { useTranslation } from "react-i18next";

//CSS Module
import styles from "./Info.module.css";

//Components
import NotifyInput from "../inputs/NotifyInput";

const info = [
  {
    title: "Home.lb_info_title_1",
    description: "Home.lb_info_description_1",
  },
  {
    title: "Home.lb_info_title_2",
    description: "Home.lb_info_description_2",
  },
  {
    title: "Home.lb_info_title_3",
    description: "Home.lb_info_description_3",
  },
];

export default function Info() {
  const { t } = useTranslation();

  return (
    <div className={styles.infoContaier}>
      <div className={styles.infoLeftBox}>
        <div className={styles.infoTitle}>{t("Home.lb_info_main_title")}</div>
        <div className={styles.infoFlex}>
          {info.map((i, index) => {
            return (
              <div key={i.title} className={styles.infoFlexContainer}>
                <div className={styles.infoFlexContainerContent}>
                  <div className={styles.infoFlexTitle}>{t(i.title)}</div>
                  <div className={styles.infoFlexDescription}>
                    {t(i.description)}
                  </div>
                </div>
                {info.length - 1 !== index && (
                  <div className={styles.divider} />
                )}
              </div>
            );
          })}
        </div>
        <div className={styles.notifyInput}>
          <NotifyInput />
        </div>
      </div>
      <div className={styles.screenshotContainer}>
        <div className={styles.gradient} />
        <div className={styles.phoneScreenshot} />
      </div>
    </div>
  );
}
