import { Helmet } from "react-helmet";
import LegalLayout from "../../components/LegalLayout";

export default function CookiePolicy() {
  return <div>
      <Helmet>
        <title>Swift Menu App - Cookie Policy!</title>
      </Helmet>
      <LegalLayout StorageFileName={"CookiePolicy.json"} />
    </div>;
}
