import { db } from "../FirebaseConfig/Firebase";
import {
  getDoc,
  doc,
  getDocs,
  collection,
  query,
  where,
  addDoc,
} from "firebase/firestore";

//Analytics
import { monitoring } from "@Ethical-Algorithm/reactjs-ea-logger-kit";

//Get company document by id
export const fetchCompanyById = async (companyID) => {
  try {
    const companyDocRef = doc(db, "companies", companyID);
    const companyDocSnapshot = await getDoc(companyDocRef);

    if (companyDocSnapshot.exists()) {
      return { id: companyDocSnapshot.id, ...companyDocSnapshot.data() };
    } else {
      console.error("Document not found");
      return null;
    }
  } catch (error) {
    monitoring.error(error);
    console.error(`Error fetching company: ${error.message}`);
    throw error;
  }
};

//Get Menu Items by company id
export const fetchMenuItemsByCompanyId = async (companyID) => {
  try {
    const querySnapshot = await getDocs(
      query(collection(db, "menu_items"), where("companyID", "==", companyID))
    );

    const product = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return product;
  } catch (error) {
    monitoring.error(error);
    console.error(`Error fetching menu items: ${error.message}`);
    throw error;
  }
};

//Set the order in the database
export const setOrder = async (order) => {
  try {
    const docRef = await addDoc(collection(db, "orders"), order);
    return docRef.id; //Return the order id
  } catch (error) {
    monitoring.error(error);
    console.error(`Error setting order: ${error.message}`);
    throw error;
  }
};
