// RouteTracker.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { monitoring } from "@Ethical-Algorithm/reactjs-ea-logger-kit";

export function RouteTracker() {
  const location = useLocation();

  const pageName = function (path) {
    const pathName = path.split("/").filter((item) => item !== "");
    switch ("/" + (pathName[0] || "")) {
      case "/":
        return "Home";
      case "/company":
        return "Company";
      case "/privacy-policy":
        return "Privacy Policy";
      case "/terms-and-conditions":
        return "Terms and Conditions";
      case "/cookie-policy":
        return "Cookie Policy";
      default:
        return path;
    }
  };

  useEffect(() => {
    monitoring.breadcrumb(
      "[Page View]",
      `Page view: ${pageName(location.pathname)}`,
      "info"
    );
  }, [location]);

  return null;
}
