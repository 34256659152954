import { useMemo } from "react";

function useCurrencyFormatter() {
  const locale =
    localStorage.getItem("LANGUAGE") ||
    navigator.language ||
    navigator.userLanguage ||
    "en";

  const formatter = useMemo(
    () =>
      new Intl.NumberFormat(locale, {
        // style: "currency",
        // currency: "USD", // €, $, £
        minimumFractionDigits: 2,
      }),
    [locale]
  );

  return (amount) => formatter.format(amount);
}

export default useCurrencyFormatter;

// --to use it--
// const formatCurrency = useCurrencyFormatter();
// console.log(formatCurrency(product?.price.normal));
