//Material Ui
import { Box, Grid, Divider, ButtonBase } from "@mui/material";

//Styles
import styles from "../Details.module.css";

//Utils / Hoocks
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { useTranslation } from "react-i18next";

export default function ProductExtras({
  extras,
  selectedPortion,
  currencyCode,
  setSelectedExtras,
  selectedExtras,
  userLanguage,
  defaultLanguage,
}) {
  const { t } = useTranslation();

  //======================| Extras Select |======================

  const handleSelectExtras = (extra) => {
    if (selectedExtras.includes(extra)) {
      setSelectedExtras(selectedExtras.filter((item) => item.id !== extra.id));
    } else {
      setSelectedExtras([...selectedExtras, extra]);
    }
  };

  return (
    <>
      {extras.length > 0 && (
        <Grid className={styles.extrasContainer} item>
          <Grid container sx={{ mb: "4px" }}>
            <div style={{ fontSize: "20px" }}>{t("menu.lb_menu_extras")}</div>
          </Grid>
          {extras.map((extra, index, array) => {
            const isLastItem = index === array.length - 1;
            const hasMultipleItems = array.length > 1;
            return (
              <div key={extra.id}>
                <ButtonBase
                  className={styles.buttonBase}
                  onClick={() => handleSelectExtras(extra)}
                >
                  <Box className={styles.optionComponet}>
                    <div style={{ textAlign: "left" }}>
                      <div style={{ lineHeight: "20px" }}>
                        {capitalizeFirstLetter(
                          extra.translation[userLanguage] ||
                            extra.translation[defaultLanguage]
                        )}
                      </div>
                      {extra.id !== selectedPortion && (
                        <div className={styles.price}>
                          {"+" + Number(extra.price).toFixed(2)} {currencyCode}
                        </div>
                      )}
                    </div>

                    <input
                      className={styles.checkBox}
                      type="checkbox"
                      name="extras"
                      checked={selectedExtras.includes(extra)}
                      onChange={() => handleSelectExtras(extra)}
                    />
                  </Box>
                </ButtonBase>
                {!isLastItem && hasMultipleItems && (
                  <Divider
                    flexItem
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.06);",
                    }}
                  />
                )}
              </div>
            );
          })}
        </Grid>
      )}
    </>
  );
}
