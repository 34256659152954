import { useTranslation } from "react-i18next";

//Assets
import img_swift_menu from "assets/img_swift_menu.svg";

//Css Modules
import styles from "./Navbar.module.css";

export default function Navbar() {
  const { t } = useTranslation();

  const scrollToContact = () => {
    const contact = document.getElementById("contactOnHome");
    const contactPosition =
      contact.getBoundingClientRect().top + window.pageYOffset - 100;
    window.scrollTo({ top: contactPosition, behavior: "smooth" });
  };

  return (
    <div className={styles.navContainer}>
      <div className={styles.navContent}>
        <img
          src={img_swift_menu}
          alt="img_swift_menu"
          className={styles.navLogo}
        />
        <button onClick={scrollToContact} className={styles.navButton}>
          {t("Home.lb_contact_button")}
        </button>
      </div>
    </div>
  );
}
