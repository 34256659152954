import { Fab, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function OrderPrice({ handleAddToCart, disabled, features }) {
  const { t } = useTranslation();
  return (
    <Grid
      sx={{
        "& > :not(style)": { width: "100%" },
        position: "fixed",
        bottom: 0,
        p: "20px",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        maxWidth: "800px",
      }}
    >
      <Fab
        disabled={disabled}
        variant="extended"
        size="medium"
        onClick={handleAddToCart}
        sx={{
          color: "#fff",
          backgroundColor: "#000",
          textTransform: "none",
          p: "26px 8px",
          borderRadius: "999px",
          fontSize: "16px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "&:hover": {
            backgroundColor: "#000",
          },
        }}
      >
        <Grid>
          +{" "}
          {features?.hasoRDERS
            ? t("menu.lb_menu_details_add_to_list")
            : t("menu.lb_menu_details_add_to_order")}
        </Grid>
      </Fab>
    </Grid>
  );
}
