//Material Ui
import { IconButton } from "@mui/material";

//Assets
import ic_close from "assets/icons/ic_close.svg";

export default function CloseButton({ setOpen }) {
  return (
    <div
      style={{
        position: "fixed",
        top: "auto",
        zIndex: 1,
      }}
    >
      <IconButton
        onClick={() => setOpen(false)}
        sx={{
          boxShadow: "0px 1px 12px 0px rgba(0, 0, 0, 0.10)",
          m: "16px",
          backgroundColor: "#fff",
          "&:hover": {
            backgroundColor: "#fff",
          },
        }}
      >
        <img src={ic_close} alt="Close" style={{ padding: "5px" }} />
      </IconButton>
    </div>
  );
}
